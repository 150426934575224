<template>
    <v-layout class="page" fill-height>
        <v-form ref="registForm" lazy-validation>
        <div class="box-container">
            <div class="conBox">
                <table class="tbl tblInp">
                    <caption>사용자 계정 등록</caption>
                    <colgroup>
                        <col style="width:300px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{{ $t('page.user.id') }} <span class="em">*</span></th>
                            <td>
                                <div style="display: flex; gap: 10px;">
                                    <Input 
                                        :label="''"
                                        :type="'text'"
                                        :name="'loginId'"
                                        :required=true
                                        :width="'fullWidth'"
                                        v-model="loginId"
                                        :autocomplete="'off'"
                                        :fnName="notNullRules('ID')"
                                        :maxLength="20"
                                    />
                                    <BtnCompo
                                        :width="'25%'"
                                        :height="'34px'"
                                        :txtBtn="buttonText"
                                        :fontSize="'14px'"
                                        :fontWeight="'500'"
                                        :borderRadius="'4px'"
                                        :marginBottom="'5px'"
                                        :onClick="() => {
                                            checkUserId();
                                        }" 
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ $t('page.user.name') }} <span class="em">*</span></th>
                            <td>
                                <Input 
                                    :label="''"
                                    :type="'text'"
                                    :name="'userNm'"
                                    :required=true
                                    :width="'fullWidth'"
                                    v-model="userNm"
                                    :fnName="notNullRules($t('page.user.name'))"
                                    :maxLength="20"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{{ $t('page.user.pw') }} <span class="em">*</span></th>
                            <td>
                                <div class="input-wrapper" style="width: 100%">
                                    <v-text-field name="loginPw" maxLength="20" required="true" v-model="loginPw" density="compact" hide-details="auto" flat solo
                                        :type="showPassword? 'text':'password'" 
                                        :rules="notNullRules($t('page.user.pw'))"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword"
                                    />
                                    <span v-if="error">{{ errorMsg }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ $t('page.user.remark') }}</th>
                            <td>
                                <v-textarea flat solo density="compact" hide-details="auto" v-model="note"></v-textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btnW mt40">
                    <button class="btn default" @click="$event.preventDefault(); $router.push({name:'userManage'})" v-ripple>{{$t('common.button.list')}}</button>
                    <button class="btn solid" @click="save($event);" v-ripple>{{$t('common.button.reg')}}</button>
                </div>
            </div>
        </div>
        </v-form>
        <v-dialog v-model="openPopup" width="320">
             <DefaultPopup
                 :popupText="msg"
                 :secondTxt="popupButton"
                 :firstBtn="false"
                 :onClick="() => { popupProgress(isError) }"
                 @closePopup="openPopup = false"
             />
         </v-dialog>
    </v-layout>
</template>
<script>
import API_CHECK_ID from '../../../API/user/checkUserId'; 
import API_REGIST_USER from '../../../API/user/sign'; 
import { rules } from '../../../mixin/rules'
export default {
    mixins: [rules],
    inject: ["setRightSideHidden", "getRightSideHidden"],
    components: {
    },
    props: {},
    data() {
        return {
            userNm: '',
            loginId: '',
            loginPw: '',
            chkLoginPw: '',
            note: '',
            crtId: '',
            openPopup:false,
            msg: '',
            isError: false,
            apiFlag: false,
            checkIdValid: false,
            showPassword: true,
            error: false,
        };
    },
    watch: {
    },
    created() { },
    mounted() {
        // 유효성 검사 초기화
        this.$refs.registForm.resetValidation();
        this.setRightSideHidden(false);
        this.crtId = this.userInfo.loginId;
        this.showPassword = true;
    },
    destroyed() { },
    methods: {
        async save(e) {
            e.preventDefault();
            // 유효성 검사
            const validate = this.$refs.registForm.validate();
            if (validate) {
                if(this.apiFlag) return;
                if(!this.checkIdValid) {
                    this.showPopup(this.$t('page.user.regist.msg.check'))
                    return;
                }
                this.apiFlag = true;
                let res = await API_REGIST_USER.request(
                    this.loginId,
                    this.userNm,
                    this.loginPw,
                    this.note
                )
                this.apiFlag = false;
                if (res.isSuccess) { // 성공
                    this.isError = false;
                    this.msg = this.$t('page.user.regist.msg.success');
                    this.openPopup = true;
                } else { // 실패
                    this.showPopup(res.errorMsg, res.status);
                }
            }
        },
        popupProgress(isError) {
            if (!isError) {
                this.$router.push({name:'userManage'});
            }
            this.openPopup = false;
        },
        async checkUserId(){
            if(!this.loginId) {
                this.showPopup(this.$t('page.user.regist.msg.insertId'))
                return
            }else{
                let res = await API_CHECK_ID.request(this.loginId);
                if(res.isSuccess){
                    this.showPopup(this.$t('page.user.regist.msg.checkId'))
                    this.checkIdValid = true;
                }else{
                    this.showPopup(res.errorMsg, res.status);
                }
            }

        }

    },
    computed: {
        popupButton(){
            return this.$t('common.button.ok')
        },
        buttonText(){
            return this.$t('page.user.regist.button')
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 10px 24px !important;
    min-width: 140px !important;
    height: var(--inputH) !important;
    font-size: var(--fz-body);
    border-radius: 100px;
    transition: background .3s, border .3s, box-shadow .3s, color .3s;
    &.danger {
    background: #fff;
    border: 1px solid #ccc;
    color: #777;
        &:hover{
            border: 1px solid var(--color-red);
            color: var(--color-red);
            box-shadow: 0 4px 7px rgba(0,0,0,0.1);
        }
    }
    &.solid {
        background: var(--color-point);
        color: #fff;
        border: 1px solid rgba(255,255,255,0.5);
        &:hover {
            box-shadow: 0 4px 7px rgba(0,0,0,0.1);
        }
    }
    &:hover, :focus {
        text-decoration: none;
        cursor: pointer;
    }

}

.tbl{overflow:hidden;}
::v-deep .v-input__slot{padding: 0 !important;}
::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot{margin-right: 0 !important}
::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot textarea{margin-top: 0 !important; border-radius: 4px !important;padding: 10px;}

::v-deep .v-text-field.v-text-field--solo .v-input__control .v-input__slot{min-height: auto; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; min-height: 34px; padding: 10px;}
::v-deep .v-text-field.v-text-field--solo .v-input__control input{border: 0; background: none; border-radius: 0;}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{padding: 0; margin-bottom: 0;}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner{padding: 0 10px;}
</style>